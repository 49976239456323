import Icon from '@mdi/react';
import {mdiArrowDownThick} from '@mdi/js';
import {ReactNode} from "react";
import {SectionWithSideLabel} from "./SectionWithSideLabel";

const FORMS_LINK = "https://docs.google.com/forms/d/e/1FAIpQLSfHb_1ip4rODVXKJGMBUgml23Mw0V95pJdTMVwn6jnvvZYd2A/viewform?usp=dialog";
const NOODLE_BOWL_BSKY_HANDLE = "noodlebowl.org"

function getBskyProfileLink(handle: String) {
    return `https://bsky.app/profile/${handle}`;
}

function DownArrowIcon() {
    return <div style={{
        margin: "auto auto"
    }}>
        <Icon path={mdiArrowDownThick} size={3}/>
    </div>;
}

function MainColumn(props: { children: ReactNode }) {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: "800px",
            width: "90%"
        }}>
            {props.children}
        </div>
    );
}

function AboutSection() {
    return <SectionWithSideLabel label={"About us"} labelWidth={120}>
        <p>
            The Noodle Bowl formed in early 2020, when Ren Tradewind was looking for a way to remain in touch with
            fellow furries during pandemic lockdowns. The Discord server initially revolved primarily around monthly
            VRChat meetups, but over the years lots of new members have joined as word spread.
        </p>
        <p>
            We are a community of furries mainly centered around dragons, kobolds and anything reptilian! What started
            as a US-based means to coordinate VR meetups has grown into a worldwide server where nerdy and creative folk
            come to hang out. From IT interests to artistic ambitions to casual chatting, this is a space for everyone.
        </p>
    </SectionWithSideLabel>;
}

function EventsSection() {
    return <SectionWithSideLabel label={"Events"} labelWidth={120}>
        <p>
            As our community grew, our hosted events have grown too! We coordinate a large VRChat meetup once
            every month, with concurrent attendee count peaking at 30-40 (almost a full instance usually).
            Keep in mind that VR is not necessary to join! You only need a desktop computer - and, with the development
            of VRC for Android, even your pocket device will suffice!
        </p>
        <p>
            Alongside our monthly meetups, we occasionally organize smaller events, such as VRC karaoke,
            Jackbox game nights, or smaller VR get-togethers. Community members are welcome to host events too!
        </p>
    </SectionWithSideLabel>;
}

function JoiningSection() {
    return <SectionWithSideLabel label={"Joining"} labelWidth={120}>
        <p>
            We are currently invite-only, but any and all furries are welcome to join! All we ask is to be kind
            and respectful to your fellow furry folk. And if you're just curious about our VRChat meetups
            and prefer to lurk, you're welcome to hop in as well!
        </p>
        <p>
            <i>
                Our community is for a general audience, we don't allow suggestive or adult art or other content
                (explained in greater detail in our server rules).
                Make sure you're okay with this!
            </i>
        </p>
        <p>
            We currently receive applications via Google Forms. Our preferred means of communication is through <a
            href={getBskyProfileLink(NOODLE_BOWL_BSKY_HANDLE)}>Bluesky</a>. While we may be able to reach out through
            other means, using Bluesky lets us get back to you faster and more reliably.
        </p>
        <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
        }}>
            <a href={FORMS_LINK} style={{
                textDecoration: "none",
            }}>
                <div style={{
                    cursor: "pointer",
                    fontVariant: "small-caps",
                    backgroundColor: "#213244",
                    color: "#EEEEE9",
                    padding: 10,
                    borderRadius: "10px",
                    border: "3px solid #7D8B9B",
                }}>
                    Apply to join
                </div>
            </a>
        </div>
    </SectionWithSideLabel>;
}

function Main() {
    return <div style={{
        width: "100%",
        minHeight: "100vh",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflowX: "hidden"
    }}>
        <div style={{
            width: "100%",
            maxHeight: "100vh",
            overflowX: "hidden",
            padding: 10,
        }}>
            <div style={{
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
            }}>
                <img src="/The_Noodle_bowl_logo.svg" alt={"Noodle Bowl logo"} style={{
                    flex: "1 0px",
                    height: 200,
                    width: "90%"
                }}/>
                <h1>A Discord community with monthly VRChat meetups!</h1>
                <DownArrowIcon/>
            </div>
        </div>
        <MainColumn>
            <AboutSection/>
            <EventsSection/>
            <JoiningSection/>
        </MainColumn>
    </div>
}

export default Main;